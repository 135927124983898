body {
    margin: 0;
    padding: 0;

    /* https://tachyons.io/docs/typography/font-family/ */
    font-family: georgia,
    times,
    serif;
}

h1 {
    text-align: center;
}

table {
    border-collapse: collapse;
    margin: 5px 0;
}
th, td {
    border: 1px solid black;
    padding: 5px;
}
.cell {
    white-space: nowrap;
    text-align: center;
}
.cell > input {
    text-align: right;
}
tbody#main input[type="number"] {
    width: 7ch; /* The HTML5 number selector widget takes 2 characters of space */
}

tfoot, tbody#totals {
    text-align: center;
}

/* TODO align form fields */

/* This is for the carbohydrate timing table */
table ul {
    padding: 0;
}
table li {
    list-style: none;
}

h3 {
    margin: 0 auto;
    text-align:center;
}

#carbohydrate-intake-timing tr td {
    max-width: 24ch;
}
#carbohydrate-intake-timing tr:last-child {
    max-width: 66ch;
}

main {
    max-width: 1100px;
    margin: 20px auto;
}

#body {
    margin: 10px 0;
}
#body label {
    display: inline-block;
    width: 18ch;
    text-align: right;
    font-weight: 700;
}
#body input {
    width: 7ch;
    text-align: right;
    margin-left:8px;
}
.ffm {
    margin: 3px 0;
    padding-left: 40px;
}